import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "list-group-item list-group-item-action active",
  "aria-current": "true"
}
const _hoisted_2 = { class: "d-flex w-100 flex-column bd-highlight" }
const _hoisted_3 = { class: "mb-0 text-decoration-underline" }
const _hoisted_4 = { class: "ms-0 mb-1 text-muted" }
const _hoisted_5 = { class: "mb-1 ms-5" }
const _hoisted_6 = { class: "fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.productName), 1),
      _createElementVNode("small", _hoisted_4, _toDisplayString(_ctx.$t('CODE')) + " " + _toDisplayString(_ctx.code), 1)
    ]),
    _createElementVNode("p", _hoisted_5, [
      _createTextVNode(_toDisplayString(_ctx.$t('COLOR')) + " ", 1),
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.color), 1)
    ])
  ]))
}