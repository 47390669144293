<template>
<ModalForm
    id="modalDetailOutputEditForm"
    :title="titleModal"
    :validationSchema="validationSchema"
    :initialValues="initialValues"
    :fnCallbackSubmit="handleCrudAction"
    :readOnly="readOnly"
    ref="modalForm"
>
    <!-- ALMACÉN Y PROVEEDOR-->
    <RowForm :twoColums="true">
        <template v-slot:default>
            <label class="required fs-6 fw-bold mb-2">Artículo</label>
            <SelectFilterRemote
                class="itemControl"
                name="productId"
                :multiple="false"
                placeholder="Producto"
                :disabled="isEditing"
                :options="comboProducts"
                @search="onRemoteSearchProducts"
                @change="handleChangeSelectedProduct"
            />
        </template>
        <template v-slot:col2>
            <label class="required fs-6 fw-bold mb-2">Unidad de Medida</label>
            <SelectOption
                class="itemControl"
                name="unitOfMeasurementId"
                :options="comboUnitMeasurements"
                :disabled="true"
            />
            
        </template> 
    </RowForm>
     <RowForm class="paddingTop" :twoColums="true">
            <template v-slot:default>
                <label class="required fs-6 fw-bold mb-2">Color</label>
                <Select :name="'color'" :disabled="true" 
                    :options="colorOptions"
                    class="itemControl">

                </Select>
            </template>
            <template v-slot:col2>
                <label class="required fs-6 fw-bold mb-2">Costo</label>
                <InputText :name="'price'"  
                    :mask="maskMoney" 
                    :disabled="true"  
                    @change="onChangePrice" 
                    class="itemControl"></InputText>
            </template> 
        </RowForm>
    <!-- Cantidad Y Total-->
      <RowForm class="paddingTop" :twoColums="true">
            <template v-slot:default>
                <label class="required fs-6 fw-bold mb-2">Cantidad</label>
                <Field  name="quantity" v-slot="{ value = 1, field, errorMessage }">
                    <el-form-item :error="errorMessage">
                        <el-input
                            class="itemControl "
                            v-bind="field"
                            :min="1"
                            placeholder="Cantidad"
                            :validate-event="false"
                            :disabled="readOnly"
                            :model-value="value"
                            @change="onChangeQuantity"
                        />
                    </el-form-item>
                </Field>
            </template>
            <template v-slot:col2>
                    <label class="fs-6 fw-bold mb-2">Total</label>
                    <InputText :name="'total'"  :mask="maskMoney" :disabled="true" class="itemControl"></InputText>
            </template>
        </RowForm>
</ModalForm>
</template>

<script>
import { defineComponent, onBeforeMount, watch, ref, toRefs, computed } from 'vue';
import { Field } from "vee-validate";
import * as Validations from "yup";
import ModalForm from "../../../components/c-modal-form/ModalFormMaster.vue";
import ApiService from "@/core/services/ApiService";
import RowForm from "../../../components/c-form-elements/RowForm.vue";
import SwalMessageService from "@/core/services/SwalMessageService";
import { getMessageError } from '@/core/helpers/messageFromPulmeros';
import SelectFilterRemote from "../../../components/forms/SelectFilterRemote.vue";
import SelectOption from "../../../components/c-form-elements/SelectOption.vue";
import Select from "../../../components/forms/Select";
import InputText from "../../../components/forms/InputText";
import { InputTextMaskTypes } from "@/core/enums/input-text-mask-types";
export default defineComponent({
    components:{
        Field,
        ModalForm,
        RowForm,
        SelectFilterRemote,
        SelectOption
        , Select
        , InputText
    },
    props: {
        mode: {
            type: String,
            required: false,
            default: "N"
        },
        titleModal: {
            type: String,
            required: false,
            default: "Nueva Entrada"
        },
        currentOpenedItem: {
            type: Object,
            required: false,
            default: () => null
        },
        fnCallBackPersistenceNotifier: {
            type: Function,
            required: false
        },
        MovementId: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const { mode, currentOpenedItem } = toRefs(props);
        const messageService = new SwalMessageService();
        const editForm = ref(null);
        let products = ref([]);
        const colorOptions = ref([]);
        let unitMeasurements = ref([]);
        const modalForm = ref(null);
        let controller = "outputs";
        let measurementId = '';
        let currentQuantity = ref([1]);
        let currentPrice = ref([0]);
        let total = ref([0]);
        const validationSchema = Validations.object().shape({
            productId: Validations.string().required().label("ProductId"),
            quantity: Validations.number().required().min(1, "El valor mínimo debe ser 1.").typeError("Sólo se adminiten valores numéricos.").label("Cantidad"),
            price: Validations.number().required().typeError("Sólo se adminiten valores numéricos.").label("Price"),
            total: Validations.number().required().typeError("Sólo se adminiten valores numéricos.").label("Total"),
            unitOfMeasurementId: Validations.string().required().label("UnitOfMeasurementName")
        });
        

        onBeforeMount(async() => {
            await getComboOptions("MeasurementUnits", unitMeasurements);
        });

        watch(currentOpenedItem, (nValue) => {
            if(currentOpenedItem.value) {
                onRemoteSearchProducts(currentOpenedItem.value.productName);
            }
        });

        watch(products, (nValue) => {
            if(products.value && currentOpenedItem.value) {
                let product = products.value.find(p => p.id == currentOpenedItem.value.productId);
                if(product) {
                    assignReadOnlyData(product);
                }
            }
        });

        const initialValues = computed(() => {
            let item = {};
            if((mode.value === "M" || mode.value === "V") && currentOpenedItem.value) {
                item.productId = currentOpenedItem.value.productId;
                item.price = currentOpenedItem.value.price;
                item.quantity = currentOpenedItem.value.quantity;
                onChangeQuantity(item.quantity);
            }
            
            return item;
        });

        const readOnly = computed(() => {
            return mode.value === "V";
        });

        const isEditing = computed(() => {
            return mode.value === "M" || mode.value === "V";
        });

        const comboProducts = computed(() => { return products.value });
        const comboUnitMeasurements = computed(() => { return unitMeasurements .value });

        //FUNCIONES
        const initDefaults = () => {
            if(modalForm.value && modalForm.value.$refs.form) {
                products.value = [];
                modalForm.value.$refs.form.resetForm();
                modalForm.value.$refs.form.setFieldValue("quantity", 1);
            }
        }

        const handleCrudAction = async(values) => {
            if(props.mode === "N") {
                await saveNewItem(values);
            } else if(props.mode === "M") {
                saveUpdatedItem(values);
            }
        }

        const saveNewItem = async(item) => {
            ApiService.post(`${controller}/Detail/${props.MovementId}`, item).then(res => {
                if(res && res.status == 201 && res.data && res.data.id) {
                    item.id = res.data.id;
                    modalForm.value.closeModal();
                    messageService.success("La información se guardó exitosamente.");
                    if(props.fnCallBackPersistenceNotifier) {
                        item.productName = products.value.find(p => p.id = item.productId).name;
                        props.fnCallBackPersistenceNotifier(item);
                    }
                }            
            }).catch(reject => {
                getMessageError(reject, messageService, 'MessageError');
            });
        }

        const saveUpdatedItem = async(item) => {
            let url = `${controller}/${currentOpenedItem.value.id}`;
            item.Id = currentOpenedItem.value.id;
            await ApiService.put(url, item).then(res => {
                if(res.status == 204) {
                    modalForm.value.closeModal();
                    messageService.success("La información se guardó exitosamente.");
                    if(props.fnCallBackPersistenceNotifier) {
                        if(products.value.some(p => p.id == item.ProductId)) {
                            item.productName = products.value.find(p => p.id == item.ProductId).name;
                        }
                        props.fnCallBackPersistenceNotifier(item);
                    }
                }            
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const getComboOptions = async(resource, container, method = "combo", parentId = "", criteria = "", filterName="criteria", callback = undefined) => {
            let parentParam = parentId.trim() != "" ? `${parentId}/` : "";
            let paramCriteria = criteria.trim() != "" ? `?${filterName}=${criteria}` : "";
            let url = `${resource}/${parentParam}${method}${paramCriteria}`;
            ApiService.query(url).then(res => {
                if(res && res.status == 200) {
                    container.value = res.data;
                    if(callback) {
                        callback(container.value);
                    }
                }
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const onRemoteSearchProducts = (query) => {
            getComboOptions("products", products, "combo", "", query, "filter", callbackSearchArticles);
        }

        const callbackSearchArticles = () => {
            products.value = products.value.map(a => {
                let article = {
                    id: a.id,
                    name: a.code + ' ' + a.name,
                    price: a.price,
                    unitOfMeasurementId: a.unitOfMeasurementId,
                    unitOfMeasurementName: a.unitOfMeasurementName,
                    minimunDiscount: a.minimunDiscount,
                    maximunDiscount: a.maximunDiscount,
                    publicPrice: a.publicPrice,
                    buyerPrice: a.buyerPrice,
                    wholesalePrice: a.wholesalePrice,
                    virtualExistence: a.virtualExistence,
                    currentExistence: a.currentExistence,
                    isInventory: a.isInventory,
                    alternateCode: a.alternateCode,
                    supplierKey: a.supplierKey,
                    productKey: a.productKey,
                    code: a.code,
                    cost: a.cost,
                    colorId: a.colorId,
                    colorName: a.colorName,
                    taxId: a.taxId,
                    taxName: a.taxName,
                    taxValue: a.taxValue,
                    discountRateSupplier: a.discountRateSupplier
                };

                return article;
            });
        }

        const handleChangeSelectedProduct = (productId) => {
            let product = products.value.find(p => p.id == productId);
            if(product) {
                assignReadOnlyData(product);
            }
        }

        const onChangeQuantity = (quantity) => {
            currentQuantity.value = parseFloat(quantity);
            calculateTotal();
        }

        const onChangePrice = (price) => {
            currentPrice.value = parseFloat(price);
            calculateTotal();
        }

        const calculateTotal = () => {
            total.value = currentQuantity.value * currentPrice.value;
            modalForm.value.$refs.form.setFieldValue("total", total.value);
        }
        const assignReadOnlyData = (product) => {
            if (product.colorId) {
                colorOptions.value = [
                    {
                        id: product.colorId
                        , name: product.colorName
                    }
                ]
            }

            unitMeasurements.value = [
                {
                    id: product.unitOfMeasurementId
                    , name: product.unitOfMeasurementName
                }
            ]
            modalForm.value.$refs.form.setFieldValue("unitOfMeasurementId", product.unitOfMeasurementId);
            modalForm.value.$refs.form.setFieldValue("color", product.colorId);
            modalForm.value.$refs.form.setFieldValue("price", product.price);
            onChangePrice(product.price);
        }

        return {
            editForm,
            modalForm,
            validationSchema,
            maskMoney: InputTextMaskTypes.Money,
            //Variables computadas
            initialValues,
            comboProducts,
            readOnly,
            isEditing,
            comboUnitMeasurements,
            colorOptions,
            //Funciones
            handleCrudAction,
            onRemoteSearchProducts,
            handleChangeSelectedProduct,
            initDefaults,
            onChangeQuantity,
            onChangePrice
        }
    },
})
</script>

<style lang="scss">

    .itemControl {
        width: 250px;
    }

    .padd {
        padding-left: 10px;
    }

    .paddingTop {
        padding-top: 15px;
    }

</style>
