
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        productName: {
            type: String
            , default: ''
        }
        , code:  {
            type: String
            , default: 'Desconocido'
        }
        , color:  {
            type: String
            , default: 'Desconocido'
        }
    }
})
