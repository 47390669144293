export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre:"])},
        "CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código:"])},
        "COLOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color:"])}
      }
    }
  })
}
